import { NextPageContext, NextComponentType } from 'next'
import { PageMetaProps, PageMeta } from '@/components/page-meta'
import React, { useState, useEffect, PropsWithChildren } from 'react'
import { useAppContext } from '@/pages/_app'
import { uiEvents } from '@/utils/event'
import flow from 'lodash/flow'

export function withPageMeta<
  P extends JSX.IntrinsicAttributes,
  C extends NextPageContext = NextPageContext,
>(
  pageMetaProps: PageMetaProps,
  ComposedComponent: NextComponentType<C, unknown, P>,
): NextComponentType<C, unknown, P> {
  const WithPageMeta = (props: P) => {
    const { t } = useAppContext()
    return (
      <>
        <PageMeta
          {...{
            ...pageMetaProps,
            subTitle: pageMetaProps.subTitle ? t(pageMetaProps.subTitle) : undefined,
            description: pageMetaProps.description ? t(pageMetaProps.description) : undefined,
          }}
        />
        <ComposedComponent {...props} />
      </>
    )
  }

  return WithPageMeta
}

export function withClientRenderOnly<
  P extends JSX.IntrinsicAttributes,
  C extends NextPageContext = NextPageContext,
>(
  ComposedComponent: NextComponentType<C, unknown, P>,
  FallbackComponent?: NextComponentType<C, unknown, P>,
): NextComponentType<C, unknown, P> {
  const Wrapper = (props: P) => {
    const [showPage, setShowPage] = useState(false)
    useEffect(() => {
      setShowPage(true)
    }, [])

    if (showPage) {
      return <ComposedComponent {...props} />
    }

    return FallbackComponent ? <FallbackComponent {...props} /> : null
  }

  if (process.env.NODE_ENV !== 'production') {
    const name = ComposedComponent.displayName || ComposedComponent.name || 'Unknown'
    // eslint-disable-next-line immutable/no-mutation
    Wrapper.displayName = `withClientRenderOnly(${name})`
  }

  return Wrapper
}

export function withLoader<
  P extends JSX.IntrinsicAttributes,
  C extends NextPageContext = NextPageContext,
>(
  ComposedComponent: NextComponentType<C, unknown, P>,
  pageIsTransient?: boolean,
): NextComponentType<C, unknown, P> {
  const Wrapper = (props: P) => {
    useEffect(() => {
      if (pageIsTransient) {
        return
      }
      setTimeout(() => {
        uiEvents.next({ type: 'AppLoaded' })
      }, 1)
    }, [])

    return <ComposedComponent {...props} />
  }

  if (process.env.NODE_ENV !== 'production') {
    const name = ComposedComponent.displayName || ComposedComponent.name || 'Unknown'
    // eslint-disable-next-line immutable/no-mutation
    Wrapper.displayName = `withLoader(${name})`
  }

  return Wrapper
}

export function withLayout<
  P extends JSX.IntrinsicAttributes,
  C extends NextPageContext = NextPageContext,
>(
  ComposedComponent: NextComponentType<C, unknown, P>,
  Layout: (props: PropsWithChildren<unknown>) => JSX.Element,
): NextComponentType<C, unknown, P> {
  const Wrapper = (props: P) => {
    return (
      <Layout>
        <ComposedComponent {...props} />
      </Layout>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    const name = ComposedComponent.displayName || ComposedComponent.name || 'Unknown'
    // eslint-disable-next-line immutable/no-mutation
    Wrapper.displayName = `withLayout(${name})`
  }

  return Wrapper
}

export type PageConfig<FallbackComponent> = {
  meta?: PageMetaProps
  clientRenderOnly?: {
    fallback?: FallbackComponent
  }
  isTransient?: boolean
  layout?: (props: PropsWithChildren<unknown>) => JSX.Element
}

export function makePage<
  P extends JSX.IntrinsicAttributes,
  C extends NextPageContext = NextPageContext,
>(
  pageConfig: PageConfig<NextComponentType<C, unknown, P>>,
  ComposedComponent: NextComponentType<C, unknown, P>,
): NextComponentType<C, unknown, P> {
  return flow(
    () => {
      return ComposedComponent
    },
    (Component) => {
      return pageConfig.layout ? withLayout(Component, pageConfig.layout) : Component
    },
    (Component) => {
      return [Component, withLoader(Component, pageConfig.isTransient)] as const
    },
    ([ComponentWithoutLoader, Component]) => {
      return pageConfig.clientRenderOnly
        ? withClientRenderOnly(Component, pageConfig.clientRenderOnly?.fallback)
        : ComponentWithoutLoader
    },
    (Component) => {
      return pageConfig.meta ? withPageMeta(pageConfig.meta, Component) : Component
    },
    (Component) => {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any, immutable/no-mutation */
      ;(Component as any).config = pageConfig
      return Component
    },
  )()
}
